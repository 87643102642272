import React from "react"
import ClickableCard from "./clickableCard"

export class CardList extends React.Component {
  render() {
    var { contents } = this.props

    var toDisplay = contents.map((cardContent, i) => {
      var {
        name,
        description,
        selected,
        nextSteps,
        icon,
        banner,
        tasks,
        why,
      } = cardContent
      return (
        <div style={{ margin: "0.5rem" }}>
          <ClickableCard
            key={i}
            name={name}
            description={description}
            selected={selected}
            nextSteps={nextSteps}
            icon={icon}
            banner={banner}
            tasks={tasks}
            why={why}
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            }}
          />
        </div>
      )
    })
    return (
      <div
        className="cardlist"
        style={{
          display: "flex",
          alignItems: "center",
          flexFlow: "row wrap",
          rowGap: "2em",
          columnGap: "2em",
        }}
      >
        {toDisplay}
      </div>
    )
  }
}

export default CardList
