/* eslint-disable */
import React from "react"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import { Link } from "gatsby"
import CardList from "../components/cardList"
import DefaultLayout from "../layouts/defaultLayout"
import { navigate } from "gatsby"
import styled from "styled-components"
import UserContext from "../context/UserContext"

const MainContainer = styled.div`
  display: flex;
  gap: 2em;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: center;
  @media (min-width: 806px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2em;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
  }
`
const LeftContainer = styled.div`
  order: 2;
  margin-bottom: 2em;
  @media (min-width: 806px) {
    order: 0;
  }
`
const RightContainer = styled.div`
  order: 1;
  margin: 0 2em 2em 2em;
  @media (min-width: 806px) {
    order: 0;
    margin-left: 0;
  }
`
const NameInput = styled.input`
  order: 2;
  margin-bottom: 2em;
  padding: 0 5px;
  border: 1px solid var(--green);
  @media (min-width: 768px) {
    order: 0;
  }
`

const EmailInput = styled.input`
  order: 4;
  padding: 0 5px;
  border: 1px solid var(--green);
  @media (min-width: 768px) {
    order: 0;
  }
`
const NameLabel = styled.p`
  order: 1;
  @media (min-width: 768px) {
    order: 0;
  }
`
const EmailLabel = styled.p`
  order: 3;
  @media (min-width: 768px) {
    order: 0;
  }
`

const FinishButton = styled.button`
  margin-top: 2rem;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`
class Join extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      emailError: "",
      subscribe: true,
      selectedInterests: [],
      modalIsOpen: false,
      referredBy: null,
    }

    this.updateEmail = this.updateEmail.bind(this)
    this.updateSubscribe = this.updateSubscribe.bind(this)
  }

  static contextType = UserContext

  componentDidMount() {
    if (this.props.location.state != null) {
      const allSelected = [
        ...this.props.location.state.projects,
        ...this.props.location.state.quickActions,
      ]
      this.setState({
        selectedInterests: allSelected,
      })
    }
    const refCode = sessionStorage.getItem("ref_code")
    console.log(refCode)
    if (refCode !== null && refCode !== undefined && refCode.length !== 0) {
      const refEmail = window.atob(refCode)
      this.setState({ referredBy: refEmail })
    }
  }

  updateData = interests => {
    const increment = firebase.firestore.FieldValue.increment(1)

    // Increment user count

    firebase
      .firestore()
      .collection("data")
      .doc("userData")
      .update({ numberOfUsers: increment })
      .then(console.log("User count incremented"))
      .catch(function(error) {
        console.error("Error incrementing user count: ", error)
      })

    // Add started projects
    interests.map(interest => {
      firebase
        .firestore()
        .collection("data")
        .doc("startedProjectData")
        .update({
          totalNumberStarted: increment,
          [interest.id]: increment,
        })
        .then(console.log("Started projects updated"))
        .catch(function(error) {
          console.error("Error adding started project: ", error)
        })
    })

    // Store separated user data

    const wizard = this.context.wizardData
    for (var i in wizard) {
      firebase
        .firestore()
        .collection("data")
        .doc("userData")
        .collection("userInfo")
        .doc(`${i}`)
        .update({
          [wizard[i]]: increment,
        })
        .then(console.log("User info updated"))
        .catch(function(error) {
          console.error("Error updating user info: ", error)
        })
    }
  }

  registerUser = () => {
    const signUp = email => {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: process.env.GATSBY_FIREBASE_URL,
        // This must be true.
        handleCodeInApp: true,
      }

      // firebase
      //   .auth()
      //   .sendSignInLinkToEmail(email, actionCodeSettings)
      //   .then(function() {
      //     // The link was successfully sent. Inform the user.
      //     // Save the email locally so you don't need to ask the user for it again
      //     // if they open the link on the same device.
      //     window.localStorage.setItem("emailForSignIn", email)
      //   })
      //   .catch(function(error) {
      //     console.log(error)
      //   })
      const cur = this
      // Generate random hash key for password
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-?"
      var charactersLength = characters.length
      for (var i = 0; i < 30; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      var startI = parseInt(Math.random() * 5)
      var endI = parseInt(Math.random() * 5 + 25)
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          this.state.email,
          result.substring(startI, endI)
        )
        .then(function() {
          console.log("Account successfully created")
          cur.updateData(cur.state.selectedInterests)
          navigate("/dashboard")
        })
        .catch(function(error) {
          console.log("Error creating account: ", error)
        })
    }

    signUp(this.state.email)
  }

  addUser = e => {
    e.preventDefault()
    if (
      this.state.emailError === "" ||
      this.state.email !== "" ||
      this.state.email !== " "
    ) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(this.state.email)
        .set({
          email: this.state.email,
          name: this.state.name,
          subscribe: this.state.subscribe,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          referredBy: this.state.referredBy,
          interests: this.state.selectedInterests.map(
            ({ nextSteps, image, tasks, ...keepAttrs }) => ({
              ...keepAttrs,
              tasks: tasks.map(({ description, toDo, ...keep }) => keep),
            })
          ),
          tasks: this.state.selectedInterests
            .map(interest =>
              interest.tasks.map(task => {
                return { id: task.id, status: "undecided" }
              })
            )
            .flat(),
          info: [this.context.wizardData],
        })
        .then(() => {
          console.log(
            "Document written to Firestore with ID: ",
            this.state.email
          )
          if (this.state.referredBy && this.state.referredBy.length > 0) {
            firebase
              .firestore()
              .collection(process.env.GATSBY_FIRESTORE_USERS)
              .doc(this.state.referredBy)
              .get()
              .then(doc => {
                if (doc.exists) {
                  const curRefs = doc.data().referredTo
                  let totalRefs = []
                  if (curRefs && Array.isArray(curRefs) && curRefs.length > 0) {
                    totalRefs = [...curRefs, this.state.email]
                  } else {
                    totalRefs = [this.state.email]
                  }
                  firebase
                    .firestore()
                    .collection(process.env.GATSBY_FIRESTORE_USERS)
                    .doc(this.state.referredBy)
                    .update({ referredTo: totalRefs })
                    .then(() => {
                      console.log("Successfully updated referer data")
                      // Increment referral data

                      const increment = firebase.firestore.FieldValue.increment(
                        1
                      )
                      firebase
                        .firestore()
                        .collection("data")
                        .doc("referrals")
                        .update({ numberOfUsers: increment })
                        .then(console.log("User count incremented"))
                        .catch(function(error) {
                          console.error(
                            "Error incrementing user count: ",
                            error
                          )
                        })
                    })
                    .catch(function(error) {
                      console.error("Error updating referer data: ", error)
                    })
                } else {
                  console.log("Referer doesn't exist")
                }
              })
              .catch(function(error) {
                console.error("Error updating referer data: ", error)
              })
          }
        })
        .catch(function(error) {
          console.error("Error adding document: ", error)
        })

      firebase
        .database()
        .ref(process.env.GATSBY_FIRESTORE_USERS + "/")
        .push({
          email: this.state.email,
          subscribe: this.state.subscribe,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          interests: this.state.selectedInterests.map(
            ({ nextSteps, image, ...keepAttrs }) => keepAttrs
          ),
        })
        .then(
          console.log(
            "Document written to Database with ID: ",
            this.state.email
          )
        )
        .catch(function(error) {
          console.error("Error adding: ", error)
        })

      // this.setState({
      //   subscribe: false,
      //   emailError: "",
      //   modalIsOpen: true,
      // })
    } else {
      console.log("ERROR")
    }

    this.registerUser()
    // this.updateData(this.state.selectedInterests)
  }

  updateEmail = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
    const isValid = this.validate()
    if (isValid) {
      this.setState({ emailError: "" })
    }
  }

  updateName = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  updateSubscribe = e => {
    this.setState({
      subscribe: e.target.checked,
    })
  }

  validate = e => {
    let emailError = ""
    // if (!this.state.email.includes("@")) {
    // if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
    if (
      !/^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/.test(
        this.state.email
      )
    ) {
      emailError = "Invalid Email"
    }

    if (emailError) {
      this.setState({ emailError })
      return false
    }
    return true
  }
  render() {
    // If we DON'T come directly from the Personalized Projects page
    if (this.props.location.state == null) {
      return (
        <DefaultLayout>
          <Link to="/">Go Home</Link>
        </DefaultLayout>
      )
    }

    // If we come direct from PersonalizedProjects page
    if (this.props.location.state != null) {
      return (
        <DefaultLayout>
          <SEO title="Email Confirmation" />
          <MainContainer>
            {/* LEFT */}
            <LeftContainer>
              {this.state.selectedInterests.length === 1 && (
                <h3
                  style={{
                    textAlign: "center",
                    marginBottom: "3rem",
                    fontSize: "1.4rem",
                  }}
                >
                  Your Selected Action
                </h3>
              )}
              {this.state.selectedInterests.length !== 1 && (
                <h3
                  style={{
                    textAlign: "center",
                    marginBottom: "3rem",
                    fontSize: "1.4rem",
                  }}
                >
                  Your Selected Actions
                </h3>
              )}
              <CardList contents={this.state.selectedInterests} />
            </LeftContainer>
            {/* RIGHT */}
            <RightContainer>
              {/* <h3
                style={{
                  textAlign: "center",
                }}
              >
                Alright! Let's get you set up
              </h3> */}
              <p>
                Congratulations! You have taken a big step by committing to a
                climate action. Together we can build a world with a safe
                climate.
              </p>
              <p>
                Sign in to access your You Change Earth dashboard and check
                anytime to update and track your tasks, set reminders, and
                visualize your impact.
              </p>
              {/* <form onSubmit={this.addUser}>
                <input
                  className="email"
                  onChange={this.updateEmail}
                  type="email"
                  name="email"
                  placeholder="Enter your email..."
                  value={this.state.email}
                  aria-label="Email"
                /> */}
              <br />
              <form onSubmit={this.addUser}>
                <FormContainer>
                  <NameLabel>First Name</NameLabel>
                  <EmailLabel>Email</EmailLabel>
                  <NameInput
                    onChange={this.updateName}
                    type="name"
                    name="name"
                    placeholder="Enter your first name..."
                    value={this.state.name}
                    aria-label="Name"
                    style={{
                      height: "2.5rem",
                      borderRadius: "0.6rem",
                      borderColor: "#acacac",
                      outline: "none",
                      width: "100%",
                    }}
                  />
                  <EmailInput
                    onChange={this.updateEmail}
                    type="email"
                    name="email"
                    placeholder="Enter your email address..."
                    value={this.state.email}
                    aria-label="Email"
                    style={{
                      height: "2.5rem",
                      borderRadius: "0.6rem",
                      borderColor: "#acacac",
                      outline: "none",
                      width: "100%",
                    }}
                  />
                </FormContainer>
                {/* <label
                  htmlFor="email"
                  style={{ fontWeight: "300", margin: "10px 3px" }}
                >
                  We will send your project starter guide now and send a follow
                  up in two weeks
                </label>
                <br />
                <br /> */}
                {/* <input
                  type="checkbox"
                  name="subscribe"
                  id="subscribe"
                  checked={this.state.subscribe}
                  onChange={this.updateSubscribe}
                  aria-label="Subscribe checkbox"
                />
                <label
                  htmlFor="subscribe"
                  style={{
                    fontWeight: "100",
                    margin: "3px",
                    fontSize: "0.8em",
                  }}
                >
                  I would also like to receive other You Change Earth
                  communications and updates
                </label> */}
                {/* <button type="submit" className="button journey">
                  Send my guide now
                </button> */}
                <FinishButton
                  className="button journey"
                  type="submit"
                  style={{
                    border: "none",
                    width: "16rem",
                    height: "4rem",
                  }}
                  // onClick={() => {
                  //   this.setState({
                  //     loading: true,
                  //   })
                  // }}
                  // disabled={this.state.loading}
                >
                  FINISH
                </FinishButton>
              </form>
            </RightContainer>
          </MainContainer>

          {/* <Modal
            open={this.state.modalIsOpen}
            onClose={() => {
              this.setState({ modalIsOpen: false })
              navigate("/")
            }}
            center
            styles={modalStyles}
          >
            <h1
              style={{
                fontWeight: "600",
                fontSize: "1.3rem",
                marginBottom: "2rem",
              }}
            >
              Verify email address
            </h1>
            <p style={{ marginBottom: "2rem" }}>
              Almost there, {this.state.name}! You're on your way to making a
              meaningful difference to the planet.
            </p>
            <p>
              An email has been sent to {this.state.email} with a link to finish
              setting up your dashboard. If it does not arrive within a few
              minutes, please check your spam folder
            </p>
          </Modal> */}
        </DefaultLayout>
      )
    }
  }
}

const modalStyles = {
  modal: {
    borderRadius: "0.45em",
    textAlign: "center",
    maxWidth: "500px",
  },
}

export default Join
